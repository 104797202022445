import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { convertStringToNumber } from "@/libs/utils";
import { CategoryProjectType, ProjectType } from "@/types";
import { CheckCircle, Loader, Trash2 } from "lucide-react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import slugify from "slugify";
import { useTypeProjectContext } from "../context/typeProgramContext";
import useProgram from "../hooks/useProgram";
import { projectStatus } from "@/constants/constants";

const MenuProject = ({handleChangeStep}) => {
  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);
  const { programData, updateProgramData } = useProgram();
  const program = programData[idParams];
  const [searchParams] = useSearchParams();
  const { typesProject } = useTypeProjectContext();
  const navigate = useNavigate();
  const handleRemove = (itemSlug: string) => {
    const copyFormData = [...program?.typesProject];
    updateProgramData(
      "typesProject",
      copyFormData.filter((i) => i !== itemSlug)
    );
  };
  const paramsSlug = {
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  };
  if (!typesProject) {
    return <div>Loading...</div>; // Afficher un message de chargement si les données ne sont pas encore disponibles
  }
  return (
    <>
      <div className="flex flex-wrap mt-4 mb-5 ml-[-1rem] lg:max-w-[90%] w-full">
        {program?.typesProject?.map((slug: string, key: number) => {
          const item = typesProject?.find(
            (item: CategoryProjectType) =>
              slugify(item.name, paramsSlug) === slug
          );
          const project = program?.projects.find(
            (project: ProjectType) => project.type === slug
          );
          return (
            item && (
              <div className="basis-full relative pt-2 pb-2" key={key}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/program/${idParams}?type=${slugify(item.name, paramsSlug)}&step=2`);
                  }}
                  to={`/program/${idParams}?type=${slugify(item.name, paramsSlug)}`}
                  className={`${
                    searchParams?.get("type") === slugify(item.name, paramsSlug)
                      ? "bg-primary"
                      : "bg-primary/5"
                  } p-3 rounded-md pl-8 flex pr-6`}
                >
                  <div
                    className={`text-sm ${
                      searchParams?.get("type") ===
                        slugify(item.name, paramsSlug) && "text-background"
                    }`}
                  >
                    {item.name}
                  </div>
                  <span className="absolute left-2 top-1/2 -translate-y-1/2 w-5 h-5 flex p-0 z-10 ">
                    {project && project.status !== projectStatus.NotStarted && project.status !== projectStatus.InProgress ? (
                      <CheckCircle size={15} />
                    ) : (
                      <Loader
                        size={15}
                        className={
                          searchParams?.get("type") ===
                          slugify(item.name, paramsSlug)
                            ? "animate-spin"
                            : ""
                        }
                      />
                    )}
                  </span>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <span className="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 flex p-0 z-10 ">
                        <Trash2 size={15} />{" "}
                      </span>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          Voulez-vous vraiment le supprimer ?
                        </AlertDialogTitle>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Annuler</AlertDialogCancel>
                        <AlertDialogAction
                          onClick={() =>
                            handleRemove(slugify(item.name, paramsSlug))
                          }
                        >
                          Continue
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </Link>
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default MenuProject;
