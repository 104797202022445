import { cn } from "@/libs/utils";
import { Check } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import MenuProject from "./MenuProject";

const NavigationStep: FC<{ handleChangeStep: any; currentStep: number; idProgram: number }> = ({
  handleChangeStep,
  currentStep,
  idProgram,
}) => {
  function isHighlight(key: number) {
    return currentStep >= key + 1;
  }
  const ITEMS = [
    {
      title: "Mes programmes",
      link: "/programs",
    },
    {
      title: "Mes projets",
      link: `/program/${idProgram}?step=1`,
    },
    {
      title: "Récapitulatif du programme",
      link: "/programs/",
    },
    {
      title: "Validation  du programme",
      link: "/",
    },
  ];
  return (
    <div className="flex mb-4 mt-4 flex-row lg:flex-col sticky top-2">
      {ITEMS.map((item, key: number) =>
        isHighlight(key) ? (
          <NavLink
          handleChangeStep={handleChangeStep}
            key={key}
            item={item}
            hasMenuProject={key == 1}
            isLastElement={key + 1 == ITEMS.length}
          />
        ) : (
          <NavDiv
          handleChangeStep={handleChangeStep}
            key={key}
            item={item}
            hasMenuProject={key == 1}
            isLastElement={key + 1 == ITEMS.length}
          />
        )
      )}
    </div>
  );
};

const NavLink: FC<{
  handleChangeStep: any;
  item: { title: string; link: string };
  hasMenuProject: boolean;
  isLastElement: boolean;
}> = ({ handleChangeStep, item, hasMenuProject, isLastElement }) => {
  return (
    <div className="flex items-center flex-wrap pl-5 lg:pl-12  pb-5 lg:pt-3 lg:pb-3 mb-4 relative">
      <p
        dangerouslySetInnerHTML={{ __html: item.title }}
        className={`relative z-10 transition-all w-full font-semibold `}
      />
      {hasMenuProject && <MenuProject handleChangeStep={handleChangeStep} />}
      <div className="w-10 h-10 bg-primary flex items-center justify-center rounded-full absolute top-[75%] z-10 scale-75 lg:scale-100 lg:top-0 left-0">
        <Check color="white" />
      </div>
      <div
        className={cn(
          "w-full lg:w-[3px] h-1 lg:h-full bg-primary absolute top-[100%] lg:top-[40px] left-[19px] opacity-100 ",
          isLastElement ? "opacity-0" : ""
        )}
      ></div>
      <Link
        to={item.link}
        className="absolute left-0 top-0 w-full h-10  z-10"
      ></Link>
    </div>
  );
};
const NavDiv: FC<{
  handleChangeStep: any;
  item: { title: string; link: string };
  hasMenuProject: boolean;
  isLastElement: boolean;
}> = ({ handleChangeStep, item, hasMenuProject, isLastElement }) => {
  return (
    <div className="flex items-center flex-wrap pl-5 lg:pl-12 relative pb-5 lg:pt-3 lg:pb-3 mb-4">
      <p
        dangerouslySetInnerHTML={{ __html: item.title }}
        className={`relative z-10 transition-all w-full text-secondary/50`}
      />
      {hasMenuProject && <MenuProject handleChangeStep={handleChangeStep} />}
      <div className="w-10 h-10 bg-primary flex items-center justify-center rounded-full absolute top-[75%] z-10 scale-75 lg:scale-100 lg:top-0 left-0">
        <Check color="white" />
      </div>
      <div
        className={cn(
          "w-full lg:w-[3px] h-1 lg:h-full bg-primary absolute top-[100%] lg:top-[40px] left-[19px] opacity-100 ",
          isLastElement ? "opacity-0" : ""
        )}
      ></div>
    </div>
  );
};
export default NavigationStep;
