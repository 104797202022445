import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Eye, EyeOff } from "lucide-react";
import { useRef, useState } from "react";

const formSchema = z.object({
  email: z.string().email({ message: "Adresse email invalide" }),
  password: z.string().min(2, {
    message: "Le mot de passe doit contenir au moins 2 caractères.",
  }),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { signIn, loading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { setError } = form;

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { success } = await signIn(values.email, values.password);
    if (success) {
      toast({
        title: "Vous êtes connecté",
      });
      navigate(`/programs`);
      window.location.reload();
    } else {
      // Déclenche une erreur pour le champ mot de passe
      setError("password", {
        type: "server",
        message: "Email ou mot de passe incorrect",
      });
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Déjà un compte ?</CardTitle>
        <CardDescription>
          Accédez à votre espace et gérez vos projets.
        </CardDescription>
      </CardHeader>
      {loading ? (
        "loading..."
      ) : (
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mail</FormLabel>
                    <FormControl>
                      <Input placeholder="Mail" {...field} type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mot de passe</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Mot de passe"
                          {...field}
                          type={showPassword ? 'text' : 'password'}
                        />
                        <button onClick={(e) => {
                          e.preventDefault();
                          setShowPassword(!showPassword)
                        }} className="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 flex items-center justify-center">
                          {showPassword ? <EyeOff /> : <Eye />}
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" variant={"default"} className="w-full">
                Se connecter
              </Button>
            </form>
          </Form>
        </CardContent>
      )}
    </Card>
  );
};

export default LoginForm;
