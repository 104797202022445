import Container from "@/components/layout/Container";
import HeaderForm from "@/components/layout/HeaderForm";
import { TYPES_PROJECTS } from "@/constants/constants";
import NavigationStep from "@/programs/components/NavigationStep";
import useProgram from "@/programs/hooks/useProgram";
import { gsap } from "gsap";
import { FC, Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Lazy loading des composants Step
const Step1 = lazy(() => import("@/programs/components/steps/Step1"));
const Step2 = lazy(() => import("@/programs/components/steps/Step1"));
const Step3 = lazy(() => import("@/programs/components/steps/Step2"));
const Step4 = lazy(() => import("@/programs/components/steps/Step3"));
const Step5 = lazy(() => import("@/programs/components/steps/Step4"));
const Step6 = lazy(() => import("@/programs/components/steps/Step5"));
const Step7 = lazy(() => import("@/programs/components/steps/Step6"));
const Step8 = lazy(() => import("@/programs/components/steps/Step7"));

const StepRenderer: FC<{
  currentStep: number;
  previousStep: number;
  onCurrentChange: (step: number) => void;
}> = ({ currentStep, previousStep, onCurrentChange }) => {
  const stepRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stepRef.current) {
      gsap.fromTo(stepRef.current, { opacity: 0 }, { opacity: 1 });
    }
  }, [currentStep, previousStep]);

  return (
    <div ref={stepRef}>
      <Suspense fallback={<div>Loading...</div>}>
        {currentStep === 1 && <Step1 onCurrentChange={onCurrentChange} />}
        {currentStep === 2 && <Step2 onCurrentChange={onCurrentChange} />}
        {currentStep === 3 && <Step3 onCurrentChange={onCurrentChange} />}
        {currentStep === 4 && <Step4 onCurrentChange={onCurrentChange} />}
        {currentStep === 5 && <Step5 onCurrentChange={onCurrentChange} />}
        {currentStep === 6 && <Step6 onCurrentChange={onCurrentChange} />}
        {currentStep === 7 && <Step7 onCurrentChange={onCurrentChange} />}
        {currentStep === 8 && <Step8 onCurrentChange={onCurrentChange} />}
      </Suspense>
    </div>
  );
};

const Project: FC = () => {
  const [currentStep, setCurrentStep] = useState(() => {
    const stepLocalStorage = localStorage.getItem("step");
    return stepLocalStorage ? JSON.parse(stepLocalStorage) : 1;
  });
  const [previousStep, setPreviousStep] = useState(currentStep);

  const handleChangeStep = (step: number) => {
    setPreviousStep(currentStep); // Mémoriser l'étape précédente
    setCurrentStep(step);
    localStorage.setItem("step", JSON.stringify(step));
  };

  return (
    <Container>
      <div>
        <HeaderProjectDescription />
        <div className="flex flex-wrap">
          <div className="basis-full lg:basis-1/4 pr-4 lg:pt-16">
            <NavigationStep isActive={currentStep <= 2 ? 1 : 2} />
          </div>
          <div className="basis-full lg:basis-3/4 p-4">
            <StepRenderer
              currentStep={currentStep}
              previousStep={previousStep}
              onCurrentChange={handleChangeStep}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const HeaderProjectDescription = () => {
  const [searchParams] = useSearchParams();
  const slugTypeProject = searchParams.get("type") || "";
  const { programData } = useProgram();
  const typeProject = TYPES_PROJECTS.filter(
    (item) => item.slug === (slugTypeProject as string)
  );
  return (
    <div className="mt-[-5rem]">
      <HeaderForm
        program={
          programData?.programName
            ? `Votre programme : ${programData?.programName}`
            : undefined
        }
        project={
          typeProject[0]
            ? `Type de votre projet : ${typeProject[0]?.name}`
            : undefined
        }
        picture="/c206fe3a9671e26c067ef0948f7df7b8.jpg"
      />
    </div>
  );
};

export default Project;
