import { cn } from "@/libs/utils";
import { ProgramType } from "@/types";
import { FC, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "../ui/button";
import { useDatabase } from "@/programs/hooks/useDatabase";
import { useToast } from "../ui/use-toast";
import useProgram from "@/programs/hooks/useProgram";
import usePostData from "@/hooks/usePostData";

const ModalEditProgram: FC<{ open: boolean; onOpenChange: (open: boolean) => void; program: ProgramType }> = ({ open, onOpenChange, program }) => {
  const [name, setName] = useState(program.name);
  const [description, setDescription] = useState(program.description);
  const [loading, setLoading] = useState(false);
  const { updateProgramInDB } = useDatabase();
  const { toast } = useToast();
  const { updateProgramData } =
    useProgram();
  
  return <Dialog open={open} onOpenChange={onOpenChange}>
  <DialogContent className="sm:max-w-[600px]">
      
      <form
      onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);

          await updateProgramInDB(
            { name, 
              description 
            },
            program.id
          );

          toast({
            title: "Programme modifié avec succès",
          })
          
          window.location.reload();
      }}
      className="space-y-6"
      >
      <DialogHeader>
          <DialogTitle className="text-2xl">
          Modifier le programme
          </DialogTitle>
          <DialogDescription>
            Modifiez les informations dans les champs ci-dessous, puis validez les changements.
          </DialogDescription>
      </DialogHeader>

      <div className="flex flex-wrap items-end">
          <div className="w-full">
              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Nom<span className="text-red-500">*</span></label>
              <input
                  required
                  type="text"
                  placeholder="Nom du programme"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mt-1"
              />
          </div>
          <div className="w-full mt-4">
              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Description</label>
              <textarea rows={4} className="flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 mt-1" placeholder="Description du programme" name="" onChange={(e) => setDescription(e.target.value)}>{description}</textarea>
          </div>
      </div>

      <DialogFooter>
          <Button type="submit" disabled={loading}>
           Sauvegarder
          </Button>
      </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
}

const CardProgram: FC<{ program: ProgramType; className?: string }> = ({
  program,
  className,
}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { postData } = usePostData<any>();
  const { toast } = useToast();

  return (
    <>
    <div className={cn("relative", className)}>
      <a href={`/program/${program.id}`}>
        <div className="aspect-[255/200] overflow-hidden group rounded-[15px] flex bg-foreground relative">
          <img
            src="/image-en-attente.jpg"
            alt="Card"
            className="w-full h-full object-cover group-hover:scale-110 transition-all m-auto opacity-80 group-hover:opacity-100"
          />
          <svg
            className="absolute left-3 top-3 w-6 h-6"
            width="60"
            height="60"
            viewBox="0 0 27 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 20C2.3125 20 1.72417 19.7554 1.235 19.2663C0.745 18.7763 0.5 18.1875 0.5 17.5V2.5C0.5 1.8125 0.745 1.22417 1.235 0.735C1.72417 0.245 2.3125 0 3 0H9.46875C9.80208 0 10.12 0.0625001 10.4225 0.1875C10.7242 0.3125 10.9896 0.489583 11.2188 0.71875L13 2.5H23C23.6875 2.5 24.2763 2.745 24.7663 3.235C25.2554 3.72417 25.5 4.3125 25.5 5H11.9688L9.46875 2.5H3V17.5L5.46875 9.28125C5.63542 8.73958 5.94292 8.30708 6.39125 7.98375C6.83875 7.66125 7.33333 7.5 7.875 7.5H24C24.8542 7.5 25.5263 7.83833 26.0163 8.515C26.5054 9.1925 26.6354 9.92708 26.4062 10.7188L24.1562 18.2188C23.9896 18.7604 23.6825 19.1929 23.235 19.5163C22.7867 19.8388 22.2917 20 21.75 20H3ZM5.625 17.5H21.75L24 10H7.875L5.625 17.5ZM5.625 17.5L7.875 10L5.625 17.5ZM3 7.5V2.5V7.5Z"
              fill="white"
            />
          </svg>
          <div className="absolute right-4 top-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild className="cursor-pointer">
              <svg className="w-6 text-white [text-shadow:_1px_1px_3px_rgb(0_0_0_/_80%)] float-right cursor-pointer"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <circle cx="12" cy="12" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="12" cy="19" r="1" /></svg>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                  <DropdownMenuGroup>
                      <DropdownMenuItem className="cursor-pointer hover:bg-white focus:bg-white focus:text-black" onClick={() => {
                          setOpenEdit(true);
                      }}>
                          <span>Modifier</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem className="cursor-pointer hover:bg-white focus:bg-white focus:text-black" onClick={() => {
                        setOpenDelete(true);
                      }}>
                          <span>Supprimer</span>
                      </DropdownMenuItem>
                  </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </a>
      <div className="py-7 flex flex-wrap items-start absolute right-4 bottom-0 px-5 left-4 pointer-events-none">
        <h2 className="font-semibold text-[16px] text-background leading-5">{program.name}</h2>
      </div>
    </div>

    <ModalEditProgram open={openEdit} onOpenChange={setOpenEdit} program={program}/>

    <Dialog open={openDelete} onOpenChange={setOpenDelete}>
      <DialogContent className="sm:max-w-[600px]">
          
          <form
          onSubmit={async (e) => {
              e.preventDefault();
              
              setLoadingDelete(true);
              const res = await postData({
                  url: `${import.meta.env.VITE_IP_API}/api/program/delete/${program.id}`,
                  data: {},
              });
      
              if(res === null || res.success === undefined || res.success === null || !res.success) {
                  toast({
                    className: "text-red-500",
                      title: "Une erreur est survenue, le program n'a pas été supprimé.",
                  });
                  setLoadingDelete(false);
                  setOpenDelete(false);
                  return
              }

              toast({
                  title: "Le program a bien été supprimé.",
              });
              setLoadingDelete(false);
              setOpenDelete(false);
              window.location.reload();
              
          }}
          className="space-y-6"
          >
          <DialogHeader>
              <DialogTitle className="text-2xl">
              Supprimer un programme
              </DialogTitle>
              <DialogDescription>
                  Si vous supprimez ce programme, tous les projets dedans seront automatiquement supprimés.<br />
                  Voulez-vous vraiment continuer ?
              </DialogDescription>
          </DialogHeader>
          <DialogFooter>
              <Button type="submit" disabled={loadingDelete}>
                  Oui
              </Button>
              <Button type="button" onClick={() => {
                  setOpenDelete(false);
              }} className="bg-slate-500">
                  Annuler
              </Button>
          </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CardProgram;
