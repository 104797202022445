// Hook personnalisé pour utiliser le contexte
import { useSearchParams } from "react-router-dom";
import slugify from "slugify";
import useProgram from "./useProgram";

const useTypeProject = (
  idParams: number,
  typesProject: any[] | null | undefined
) => {
  const [searchParams] = useSearchParams();
  const { programData } = useProgram();
  let isValidUrlAndIndex = false;
  const slugTypeProject = searchParams.get("type") || "";
  const projet = typesProject?.filter(
    (item: { name: string }) =>
      slugify(item.name, {
        remove: /[*+~.()'"!:@]/g,
        lower: true,
      }) == slugTypeProject
  );
  const indexTypeProjet = programData[idParams]?.typesProject
    ? programData[idParams]?.typesProject.indexOf(slugTypeProject)
    : null;
  if (projet?.length > 0) {
    isValidUrlAndIndex = indexTypeProjet !== -1;
  }
  return { isValidUrlAndIndex, indexTypeProjet };
};
export default useTypeProject;
