import { useAuth } from "@/auth/hooks/useAuth";
import { Link } from "react-router-dom";

const Navigation = () => {
  const { isAuthenticated, logout } = useAuth();
  console.log("isAuthenticated", isAuthenticated);
  return (
    <div className="container">
      <nav className="h-24 flex items-center relative ">
        <ul className="flex items-center w-full">
          <li>
            <Link to="/">
              <img src="/logo.svg" alt="logo verdeo" width={138} />
            </Link>
          </li>
          <li className="ml-auto mr-4">
            <a href="tel:+33616928779" className="relative group">
              Contactez-nous!{" "}
              <span className="w-0 h-[1px] absolute left-0 top-[100%] bg-foreground group-hover:w-full transition-all"></span>
            </a>
          </li>
          {!isAuthenticated ? (
            <li>
              <Link
                to="/login"
                className="bg-background border-2 border-foreground rounded-[4rem] py-2 px-4 hover:bg-foreground hover:text-background transition-all"
              >
                Connexion
              </Link>
            </li>
          ) : (
            <li>
              <div
                onClick={() => logout()}
                className="cursor-pointer bg-background border-2 border-foreground rounded-[4rem] py-2 px-4 hover:bg-foreground hover:text-background transition-all"
              >
                Déconnexion
              </div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
