import NotFoundPage from "@/components/errors/404";
import Auth from "@/pages/Auth";
import EmailVerification from "@/pages/EmailVerification";
import Home from "@/pages/Home";
import Program from "@/pages/Program";
import Programs from "@/pages/Programs";
import Project from "@/pages/Projects";
import { Route, Routes } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projet" element={<Project />} />
      <Route path="/program/:id" element={<Program />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/login" element={<Auth />} />
      <Route path="/register/verify" element={<EmailVerification />} />
      <Route path="*" element={<NotFoundPage isPageChildren={false} />} />
    </Routes>
  );
};

export default AppRoutes;
