import CardProgram from "@/components/card/CardProgram";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useFetchData } from "@/hooks/useFetchData";
import usePostData from "@/hooks/usePostData";
import useProgram from "@/programs/hooks/useProgram";
import { ProgramType } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
const FormSchema = z.object({
  programName: z.string().min(1, {
    message: "Le nom du programme est obligatoire.",
  }),
  programDescription: z.string().optional(),
});

interface MyData {
  name: string;
  description: string;
}

const Programs: FC = () => {
  const { toast } = useToast();
  const { data } = useFetchData<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`
  );
  const { postData, loading, error, response } = usePostData<MyData>();
  const [programs, setPrograms] = useState<ProgramType[]>([]);
  const [open, setOpen] = useState(false);
  const { updateProgramData } = useProgram();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      programName: "",
      programDescription: "",
    },
  });

  // Mise à jour des programmes lorsque les données sont récupérées
  useEffect(() => {
    if (data) {
      setPrograms(data);
    }
  }, [data]);

  // Mise à jour des programmes après création réussie
  useEffect(() => {
    if (response?.success && response.data) {
      setPrograms((prevPrograms) => [
        ...prevPrograms,
        response.data as ProgramType,
      ]);
      toast({
        title: "Programme ajouté avec succès.",
      });
      updateProgramData("programName", response.data.name);
      updateProgramData("programDescription", response.data.description);
      updateProgramData("programId", response.data.id);

      // setRun(programs.length <= 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  function onSubmit(formData: z.infer<typeof FormSchema>) {
    postData({
      url: `${import.meta.env.VITE_API_ENDPOINT}/program/save`,
      data: {
        name: formData.programName,
        description: formData.programDescription || "",
      },
    });
    setOpen(false);
  }

  /* ---------------- Guide -------------- */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [run, setRun] = useState(false);
  const steps = [
    {
      target: "#addProgram",
      title: "Ajout Programme",
      content: (
        <div className="text-center">
          <p>
            Vous n'avez pas encore de programme dans votre liste pour le moment,
            veuillez ajouter un programme en cliquant sur ajouter
          </p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      setOpen(true);
    }
  };
  useEffect(() => {
    // setTimeout(() => {
      if(data === null || programs.length > 0){
        setRun(false);
      } else if(data !== null && programs.length <= 0){
        setRun(true);
      }
    // }, 300);
  },[programs])

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <div className="container min-h-screen">
      <Joyride
        callback={handleJoyrideCallback}
        continuous={false}
        run={run}
        scrollToFirstStep={false}
        disableScrolling={true}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#80B533",
            overlayColor: "rgba(255, 255, 255, 0.8)",
            spotlightShadow: "0 0 0 rgba(0, 0, 0, 0)",
            textColor: "#fff",
            zIndex: 1000,
          },
        }}
      />
      <div className="mb-4">
        <ReactBreadcrumb items={[{
          href: "/programs",
          name: "Mes programmes"
        }]} />
      </div>
      <h1 className="text-xl font-medium mb-3">Liste de mes programmes</h1>
      <p className="text-[#595959]">
        Un programme est un type de dossier regroupant plusieurs projets.{" "}
        <strong>La sous-division de ces programmes en projets</strong> permet un
        suivi plus fin des indicateurs et une meilleure récupération des données
      </p>
      {error && (
        <p className="mt-8" style={{ color: "red" }}>
          Error: {error}
        </p>
      )}
      {data !== null && <div className={`flex flex-wrap items-start mt-8 -mx-4`}>
        {programs?.map((program: ProgramType, key: number) => (
          <CardProgram
            className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-4 mb-4"
            key={key}
            program={program}
          />
        ))}
        <div className="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-4 flex h-full">
          <div className="aspect-[255/200] w-full " id="addProgram">
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild>
                <div className="w-full flex-col flex items-start justify-center h-full">
                  <Button
                    variant="outline"
                    className=" border rounded-[15px] flex items-center justify-center h-full w-full"
                    style={{ background: "#EFEFEF" }}
                  >
                    <div className="w-max flex  items-center">
                      <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z" fill="#D4D4D4"/>
                        <rect x="25" y="19" width="3" height="11" rx="1" fill="#D9D9D9"/>
                        <rect x="32" y="23" width="3" height="11" rx="1" transform="rotate(90 32 23)" fill="#D9D9D9"/>
                      </svg>
                    </div>
                  </Button>
                  <div className="flex items-center mt-4 text-md gap-2 font-semibold cursor-pointer">
                    <Plus /> Ajouter
                  </div>
                </div>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[600px]">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <DialogHeader>
                      <DialogTitle className="text-2xl">
                        Ajout de programme
                      </DialogTitle>
                      <DialogDescription>
                        Remplissez les informations ci-dessous pour ajouter un
                        nouveau programme à votre liste.
                      </DialogDescription>
                    </DialogHeader>

                    <FormField
                      control={form.control}
                      name="programName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            Nom du programme
                            <span className="text-destructive">*</span>
                          </FormLabel>
                          <FormControl>
                            <Input placeholder="Nom du programme" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="programDescription"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description du programme </FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder="Description du programme"
                              {...field}
                              className="w-full"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <DialogFooter>
                      <Button type="submit" disabled={loading}>
                        {loading ? "Envoie..." : "Sauvegarder"}
                      </Button>
                    </DialogFooter>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>}
      {data === null && <div className="mt-8"><LoadingSpinner /></div>}
    </div>
  );
};

export default Programs;
