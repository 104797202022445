export const TYPES_PROJECTS = [
  {
    slug: "agroforesterie",
    name: "Agroforesterie",
    icone: "",
  },
  {
    slug: "permaculture",
    name: "Permaculture",
    icone: "",
  },
  {
    slug: "conversion_agriculture_biologique",
    name: "Conversion à l'agriculture biologique",
    icone: "",
  },
  {
    slug: "jardinage_communautaire",
    name: "Jardinage communautaire",
    icone: "",
  },
  {
    slug: "culture_en_couvert_végétal",
    name: "Culture en couverts végétaux",
    icone: "",
  },
  {
    slug: "gestion_durable_sols_eau",
    name: "Gestion durable des sols et de l'eau",
    icone: "",
  },
  {
    slug: "equipements_infrastructures_transition",
    name: "Equipements et infrastructures de transition",
    icone: "",
  },
  {
    slug: "reforestation",
    name: "Reforestation",
    icone: "",
  },
  {
    slug: "haies",
    name: "Haies",
    icone: "",
  },
  {
    slug: "mangrove_restauration_zones_humides",
    name: "Mangrove et restauration des zones humides",
    icone: "",
  },
  {
    slug: "restauration_recifs_coralliens",
    name: "Restauration des récifs coralliens",
    icone: "",
  },
  {
    slug: "restauration_prairies",
    name: "Restauration des prairies",
    icone: "",
  },
  {
    slug: "restauration_sols",
    name: "Restauration des sols",
    icone: "",
  },
  {
    slug: "captage_eaux_pluie",
    name: "Captage des eaux de pluie",
    icone: "",
  },
  {
    slug: "traitement_eaux_usees_naturelles",
    name: "Traitement des eaux usées naturelles",
    icone: "",
  },
  {
    slug: "projets_conservation_eau",
    name: "Projets de conservation de l'eau",
    icone: "",
  },
  {
    slug: "reserves_naturelles",
    name: "Réserves naturelles",
    icone: "",
  },
  {
    slug: "protection_reintroduction_especes",
    name: "Protection ou réintroduction des espèces",
    icone: "",
  },
  {
    slug: "corridors_ecologiques",
    name: "Corridors écologiques",
    icone: "",
  },
  {
    slug: "ecovillages",
    name: "Ecovillages",
    icone: "",
  },
  {
    slug: "banques_semences_communautaires",
    name: "Banques de semences communautaires",
    icone: "",
  },
  {
    slug: "education_environnement",
    name: "Education à l'environnement",
    icone: "",
  },
  {
    slug: "initiatives_justice_alimentaire",
    name: "Initiatives de justice alimentaire",
    icone: "",
  },
] as const;

export const projectStatus = {
  NotStarted: 0,
  InProgress: 1,
  AwaitingValidation: 2,
  Validated: 3,
  Rejected: 4,
}


