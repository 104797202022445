import { projectStatus } from "@/constants/constants";
import {
  CategoryProjectType,
  ParcelType,
  ParcelUpdateType,
  ProjectType,
  ProjectUpdateType,
} from "@/types";
import { type ClassValue, clsx } from "clsx";
import slugify from "slugify";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const groupByFirstLetter = (items: CategoryProjectType[]) => {
  const copyItems = [...items];
  return copyItems.reduce((acc, item) => {
    const firstLetter = item.name[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {} as Record<string, CategoryProjectType[]>);
};

interface FileValidationOptions {
  allowedTypes: string[];
  maxSizeMB: number; // Max file size in MB
}

export function validateFiles(files: FileList, options: FileValidationOptions) {
  const { allowedTypes, maxSizeMB } = options;
  const validFiles: File[] = [];
  const previewUrls: string[] = [];

  Array.from(files).forEach((file) => {
    if (!allowedTypes.includes(file.type)) {
      alert(`Seuls les formats ${allowedTypes.join(", ")} sont autorisés.`);
      return;
    }
    if (file.size > maxSizeMB * 1024 * 1024) {
      alert(
        `La taille de chaque fichier doit être inférieure à ${maxSizeMB} Mo.`
      );
      return;
    }
    validFiles.push(file);
    previewUrls.push(URL.createObjectURL(file));
  });

  return { validFiles, previewUrls };
}
type dataParcel = {
  properties: {
    code_arr: string;
    code_com: string;
    code_dep: string;
    code_insee: string;
    com_abs: string;
    contenance: string;
    feuille: string;
    idu: string;
    nom_com: string;
    numero: string;
    section: string;
  };
  geometry: {
    coordinates: [];
  };
};
export function dataProcessingParcel(
  data: dataParcel,
  surface: number,
  isFrench: boolean
) {
  const processedData = {
    codeArr: data.properties.code_arr,
    codeCom: data.properties.code_com,
    codeDep: data.properties.code_dep,
    codeInsee: data.properties.code_insee,
    comAbs: data.properties.com_abs,
    contenance: data.properties.contenance,
    feuille: data.properties.feuille,
    idu: data.properties.idu,
    nomCom: data.properties.nom_com,
    numero: data.properties.numero,
    section: data.properties.section,
    coordinates: data.geometry.coordinates,
    surface: surface,
    pays: isFrench ? "France" : "Autres",
  };
  return processedData;
}

export const checkIfInFrance = (lat: number, lng: number) => {
  // Les coordonnées définissent les limites approximatives de la France
  const bounds = {
    north: 51.124199,
    south: 41.342327,
    west: -5.1406,
    east: 9.6625,
  };
  return (
    lat >= bounds.south &&
    lat <= bounds.north &&
    lng >= bounds.west &&
    lng <= bounds.east
  );
};

// Custom debounce function
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export function getInitialCoordinates(position: GeolocationPosition) {
  const posLocalStorage = localStorage.getItem("loc_map");

  if (posLocalStorage) {
    try {
      const parsedPosLocalStorage = JSON.parse(posLocalStorage);
      if (
        Array.isArray(parsedPosLocalStorage) &&
        parsedPosLocalStorage.length === 3
      ) {
        return {
          latitude: parseFloat(parsedPosLocalStorage[0]),
          longitude: parseFloat(parsedPosLocalStorage[1]),
          zoom: parseInt(parsedPosLocalStorage[2]),
        };
      } else {
        throw new Error("Invalid format in localStorage.");
      }
    } catch (error) {
      console.error("Error parsing localStorage:", error);
    }
  }

  // Fallback to the Geolocation API position
  return {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    zoom: 20, // Default zoom level
  };
}

export function getProjectTypeFields(projectTypes, projectType) {
  const fields = [];
  projectTypes.map(function (pt) {
    const slug = slugify(pt.name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    });
    if (slug === projectType) {
      fields.push(pt.fields.map((field) => field.name));
    }
  });
  if (fields.length) {
    return fields[0];
  }

  return [];
}

export function getProjectTypeId(projectTypes, projectType): number | null {
  const fields = [];
  for (let i = 0; i < projectTypes.length; i++) {
    const slug = slugify(projectTypes[i].name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    });
    if (slug === projectType) return projectTypes[i].id;
  }

  return null;
}
export function convertStringToNumber(str: string | undefined): number | null {
  if (str === null) {
    return null;
  }

  const num = parseFloat(str);
  return isNaN(num) ? null : num;
}

export function dataProcessingProject(data): ProjectUpdateType {
  const processedData: ProjectUpdateType = {
    id: data.id !== undefined ? data.id : null,
    name: data.name,
    type: data.type,
    description: data.description,
    fundRecipient:
      data.fundRecipient !== undefined && data.fundRecipient.name !== ""
        ? data.fundRecipient
        : null,
    fundLocal:
      data.fundLocal !== undefined && data.fundLocal.name !== ""
        ? data.fundLocal
        : null,
    manager:
      data.manager !== undefined &&
      (data.manager.firstname !== "" ||
        data.manager.lastname !== "" ||
        data.manager.email !== "" ||
        data.manager.society !== "")
        ? data.manager
        : null,
    location:
      data.location !== undefined && data.location.length
        ? data.location.map((loc) => loc.map((l) => [l.lat, l.lng]))
        : null,
    launchDate: data.launchDate,
    expectedDuration: data.expectedDuration,
    desiredFunding:
      data.desiredFunding !== undefined && data.desiredFunding.length
        ? data.desiredFunding.filter((df) => df.tile !== "" && df.amount !== 0)
            .length
          ? data.desiredFunding.filter(
              (df) => df.tile !== "" && df.amount !== 0
            )
          : null
        : null,
    surface: data.surface,
    linear: data.linear,
    trees: data.trees,
    sau: data.sau,
    peopleInvolved: data.peopleInvolved,
    protectedSpecies:
      data.protectedSpecies !== undefined && data.protectedSpecies.length
        ? data.protectedSpecies.filter((ps) => ps.name !== "").length
          ? data.protectedSpecies.filter((ps) => ps.name !== "")
          : null
        : null,
    plantedSpecies:
      data.plantedSpecies !== undefined && data.plantedSpecies.length
        ? data.plantedSpecies.filter((ps) => ps.name !== "").length
          ? data.plantedSpecies.filter((ps) => ps.name !== "")
          : null
        : null,
    parcels:
      data.parcel !== undefined && data.parcel.length
        ? data.parcel.map((p: ParcelType) => {
            const pf: ParcelUpdateType = {
              codeArr: p.codeArr,
              codeCom: p.codeCom,
              codeDep: p.codeDep,
              codeInsee: p.codeInsee,
              comAbs: p.comAbs,
              contenance: p.contenance,
              feuille: p.feuille,
              idu: p.idu,
              nomCom: p.nomCom,
              numero: p.numero,
              section: p.section,
              coordinates: p.coordinates[0][0],
            };

            return pf;
          })
        : null,
    files: data.files !== undefined && data.files.length ? data.files : null,
  };

  return processedData;
}

function checkStateStep2(data: ProjectType) {
  if(data.name !== "") return true
  return false
}

function checkStateStep3(data: ProjectType) {
  if(data.launchDate) return true
  return false
}

function checkStateStep4(data: ProjectType) {
  if(
    (data.desiredFunding && data.desiredFunding.length > 0) ||
    (data.obtainedFunding && data.obtainedFunding.length > 0)
  ) return true

  return checkStateStep5(data)
}

function checkStateStep5(data: ProjectType) {
  const typesProject = JSON.parse(localStorage.getItem("typesProject") || "[]")
  const fields = getProjectTypeFields(typesProject, slugify(data.type.name, {
    lower: true,
    remove: /[*+~.()'"!:@]/g,
  }));

  const fieldsInStep = fields.filter((field) => (
    field === 'surface' ||
    field === 'trees' ||
    field === 'sau' ||
    field === 'linear' ||
    field === 'peopleInvolved' ||
    field === 'protectedSpecies' ||
    field === 'plantedSpecies'
  ));

  if(fieldsInStep.length === 0) {
    return checkStateStep6(data)
  }

  if((data.surface === 0 || data.surface === null) && fields.includes("surface")) {
    return false
  }

  if((data.peopleInvolved === 0 || data.peopleInvolved === null) && fields.includes("peopleInvolved")) {
    return false
  }

  if((data.sau === 0 || data.sau === null) && fields.includes("sau")) {
    return false
  }

  if((data.linear === 0 || data.linear === null) && fields.includes("linear")) {
    return false
  }

  return true
}

function checkStateStep6(data: ProjectType) {
  if((data.parcel === null || data.parcel === undefined || data.parcel.length <= 0) && (data.location === null || data.location === undefined || data.location.length <= 0)) {
    return false
  }

  return true
}

function checkStateStep7(data: ProjectType) {
  if(data.mediaDescription && data.mediaDescription.length > 0) {
    return true
  }

  return checkStateStep8(data)
}

function checkStateStep8(data: ProjectType) {
  if(data.mediaPhotos && data.mediaPhotos.length > 0) {
    return true
  }

  return data.status !== projectStatus.NotStarted && data.status !== projectStatus.InProgress
}

function setStepStates(data: ProjectType) {
  const stepStates = {
    2: checkStateStep2(data),
    3: checkStateStep3(data),
    4: checkStateStep4(data),
    5: checkStateStep5(data),
    6: checkStateStep6(data),
    7: checkStateStep7(data),
    8: checkStateStep8(data),
  }

  return stepStates
}

export function reverseDataProcessingProject(data: ProjectType): ProjectType {
  const stepStates = setStepStates(data);

  const res: ProjectType = {
    name: data.name,
    description: data.description,
    type: slugify(data.type.name, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    }),
    fundRecipient: data.fundRecipient
      ? {
          name: data.fundRecipient.name,
          email: data.fundRecipient.email,
        }
      : {
          name: "",
          email: "",
        },
    fundLocal: data.fundLocal
      ? {
          name: data.fundLocal.name,
          uri: data.fundLocal.uri,
        }
      : {
          name: "",
          uri: "",
        },
    manager: data.manager
      ? {
          firstname: data.manager.firstname,
          lastname: data.manager.lastname,
          email: data.manager.email,
          society: data.manager.society,
        }
      : {
          firstname: "",
          lastname: "",
          email: "",
          society: "",
        },
    id: data.id,
    launchDate: data.launchDate,
    expectedDuration: data.expectedDuration,
    desiredFunding:
      data.desiredFunding && data.desiredFunding.length
        ? data.desiredFunding.map((df) => {
            return {
              id: df.id,
              title: df.title,
              amount: df.amount,
              media: df.media
            };
          })
        : [],
    obtainedFunding:
      data.obtainedFunding && data.obtainedFunding.length
        ? data.obtainedFunding.map((df) => {
            return {
              id: df.id,
              partner: df.partner,
              title: df.title,
              amount: df.amount,
              media: df.media
            };
          })
        : [],
    surface: data.surface ?? 0,
    trees: data.trees ?? 0,
    sau: data.sau ?? 0,
    linear: data.linear ?? 0,
    peopleInvolved: data.peopleInvolved ?? 0,
    protectedSpecies: data.protectedSpecies && data.protectedSpecies.length ? data.protectedSpecies.map(sp => {
      return {
        name: sp.species.name
      }
    }) : [
      {
        name: ""
      }
    ],
    plantedSpecies: data.plantedSpecies && data.plantedSpecies.length ? data.plantedSpecies.map(sp => {
      return {
        name: sp.species.name
      }
    }) : [
      {
        name: ""
      }
    ],
    location: data.location && data.location.length ? data.location.map(l => {
      return l.map(p => {
        return {
          lat: p[0],
          lng: p[1]
        }
      })
    }) : [],
    parcel: data.parcel ? data.parcel.map(p => {
      return {
        ...p,
        contenance: parseFloat(p.contenance),
        feuille: parseInt(p.feuille),
        coordinates: [[p.coordinates]],
        pays: 'France'
      }
    }) : [],
    mediaDescription: data.mediaDescription ? data.mediaDescription : [],
    mediaPhotos: data.mediaPhotos ? data.mediaPhotos : [],
    status: data.status ?? projectStatus.NotStarted,
    stepStates
  }

  return res;
}
