import React from "react";

interface ContainerProps {
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <div className="flex flex-col justify-center  min-h-screen w-full relative">
      <div className="relative z-10 container mt-[10vh] mb-5 w-full max-w-[90vw]  mx-auto shadow-lg p-10 rounded-xl bg-white">
        {children}
      </div>
      <div className="fixed bg-[url('/pexels-pixabay-235925.jpg')] bg-no-repeat bg-cover bg-fixed bottom-0 left-0 w-full h-full blur-md scale-110 brightness-[.8]"></div>
    </div>
  );
};

export default Container;
