import { FC } from "react";

type HeaderFormProps = {
  project: string;
  picture: string;
};
const HeaderForm: FC<HeaderFormProps> = ({ project, description, picture }) => {
  return (
    <div className="flex items-center mt-4">
      <div className="w-1/4 pr-4  overflow-hidden h-[150px]">
        {picture && (
          <img
            src={picture}
            alt={project}
            className="w-full h-full object-cover rounded-[20px]"
          />
        )}
      </div>
      <div className="flex flex-col justify-end z-10 px-[17px] pb-[20px] w-3/4  h-full">
        <h1 className="text-[24px] font-bold  leading-[3.5rem] mb-0 text-[#626262]">
          Projet {" "}: {" "}
          {project}
        </h1>
        {description !== '' && <p className="text-[14px] text-[#595959]">{description}</p>}
      </div>
      
    </div>
  );
};

export default HeaderForm;
